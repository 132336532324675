<template>
  <div class="wrapper">
    <pdf
      class="pdf"
      :src="pdfUrl"
      :page="page"
      @page-loaded="pageLoaded"
      @loaded="pdfLoaded"
    >
    </pdf>
  </div>
</template>

<script>
import pdf from 'vue-pdf-signature'
import CMapReaderFactory from 'vue-pdf-signature/src/CMapReaderFactory'

export default {
  name: 'EbookDetailContent',
  props: {
    page: {
      type: Number,
      default: 1
    },
    src: {
      type: String,
      default: ''
    }
  },
  components: {
    pdf
  },
  data () {
    return {
      pdfUrl: pdf.createLoadingTask({
        url: this.src,
        CMapReaderFactory
      })
    }
  },
  mounted () {
    this.$emit('showLoading')
  },
  methods: {
    pageLoaded () {
      this.$emit('clearLoading')
    },
    pdfLoaded () {
      this.$emit('clearLoading')
    }
  }
}
</script>

<style lang="stylus" scoped>
.wrapper
  width 100%
</style>

<template>
  <transition :appear="true" name="slide">
    <div @touchmove.prevent class="ebook-detail">
      <ebook-detail-header></ebook-detail-header>
      <div class="scroll" ref="wrapper">
        <div :style="{width: size + '%', 'margin-left': -((size-100)/2) + '%'}">
          <ebook-detail-content
            v-if="pdfUrl"
            @showLoading="showLoading"
            @clearLoading="clearLoading"
            :page="page"
            :src="pdfUrl"
            :size="size"
          ></ebook-detail-content>
        </div>
      </div>
      <ebook-detail-bottom
        @sizeAdd="sizeAdd"
        @sizeLess="sizeLess"
        @changePage="changePage"
        :directory-list="directory"
        @favorites="favorites"
        @share="share"
        :favorites-active="favoritesActive"
        @directorySort="directorySort"
      ></ebook-detail-bottom>
      <overlay
        :show="showShare"
      >
        <share
          :show="showShare"
          :pic-url="resourcesCover"
          :name="resourcesName"
          :author="resourcesAuthor"
          :user-name="userName"
          :agency-guid="agencyGuid"
          :user-guid="userGuid"
          @shareClose="showShare=false"
        ></share>
      </overlay>
    </div>
  </transition>
</template>

<script>
import { Overlay, Toast } from 'vant'
import BScroll from 'better-scroll'
import EbookDetailHeader from './components/Header'
import EbookDetailContent from './components/Content'
import EbookDetailBottom from './components/Bottom'
import { getResourcesDesc, setCUserLog } from '@/api/Resources'
import { mapGetters } from 'vuex'
import { appInit } from '@/utils/init'
import { getCUserFavoritesFlag, setCUserFavorites } from '@/api/Cuser'
import Share from '@/views/book-detail/components/Share'

export default {
  name: 'EbookDetail',
  activated () {
    document.title = this.$route.params.resources_name
    this._resourcesInit()
    this._initScroll()
  },
  mounted () {
    document.title = this.$route.params.resources_name
    this._resourcesInit()
    this._initScroll()
  },
  computed: {
    ...mapGetters([
      'userGuid',
      'userName',
      'agencyGuid',
      'userCityName'
    ])
  },
  components: {
    EbookDetailHeader,
    EbookDetailContent,
    EbookDetailBottom,
    Overlay,
    Share
  },
  data () {
    return {
      data: [],
      page: 1, // pdf页数
      pdfUrl: '', // pdf地址
      pageCount: 0, // pdf总页数
      size: 100, // pdf页面大小
      directory: [],
      favoritesActive: false,
      showShare: false,
      resourcesCover: '',
      resourcesName: '',
      resourcesAuthor: ''
    }
  },
  methods: {
    _resourcesInit () {
      this.showLoading()
      let data = {
        resources_guid: this.$route.params.resources_guid,
        c_user_guid: this.userGuid
      }
      if (this.$route.query.page) {
        data.page = this.$route.query.page
      }
      getResourcesDesc(data).then(res => {
        if (res.code === 200) {
          this.page = res.data.media_log
          this.pdfUrl = res.data.media_file
          // this.pdfUrl = '@/assets/pdf0001.pdf'
          this.pageCount = res.data.media_size
          this.directory = res.data.directory
          this.resourcesCover = res.data.resources_cover
          this.resourcesName = res.data.resources_name
          this.resourcesAuthor = res.data.resources_author
        } else if (res.code === 401) {
          appInit(this.agencyGuid, this.$route.path)
        } else if (res.code === 402) {
          this.$router.push({
            name: 'Login',
            params: {
              link: this.$route.path
            }
          })
        } else {
          Toast.fail(res.msg)
        }
        Toast.clear()
      })
      getCUserFavoritesFlag({
        resources_guid: this.$route.params.resources_guid,
        c_user_guid: this.userGuid
      }).then(res => {
        if (res.code === 200) {
          this.favoritesActive = res.data
        }
      })
    },
    _initScroll () {
      if (!this.$refs.wrapper) {
        return
      }
      this.scroll = new BScroll(this.$refs.wrapper, {
        freeScroll: true,
        bounce: {
          bottom: true,
          left: false,
          right: false,
          top: true
        },
        pullUpLoad: {
          threshold: -30
        },
        pullDownRefresh: {
          threshold: 30,
          stop: 0
        }
      })
      this.scroll.on('pullingDown', () => {
        this.prePage()
        this.scroll.finishPullDown()
        this.refresh()
      })
      this.scroll.on('pullingUp', () => {
        this.nextPage()
        this.scroll.finishPullUp()
        this.refresh()
      })
    },
    refresh () {
      setTimeout(() => {
        this.scroll && this.scroll.refresh()
      }, 100)
    },
    nextPage () {
      if (this.page >= this.pageCount) {
        Toast.fail('没有下一页了')
        return false
      }
      this.showLoading()
      this.page++
    },
    prePage () {
      if (this.page <= 1) {
        Toast.fail('没有上一页了')
        return false
      }
      this.showLoading()
      this.page--
    },
    showLoading () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
    },
    clearLoading () {
      Toast.clear()
    },
    sizeAdd () {
      if (this.size >= 300) {
        Toast.fail('不能再大了')
        return false
      }
      this.size += 10
      this.refresh()
    },
    sizeLess () {
      if (this.size <= 100) {
        Toast.fail('不能再小了')
        return false
      }
      this.size -= 10
      this.refresh()
    },
    changePage (page) {
      if (page < 1 || page > this.pageCount) {
        Toast.fail('目录错误')
        return false
      }
      if (page !== this.page) {
        this.showLoading()
        this.page = page
      }
    },
    favorites (flag) {
      this.showLoading()
      setCUserFavorites({
        c_user_guid: this.userGuid,
        favorites_obj: this.$route.params.resources_guid,
        favorites_type: 1,
        favorites_status: flag ? -1 : 1
      }).then(res => {
        if (res.code === 200) {
          this.favoritesActive = !flag
          Toast.clear()
        }
      })
    },
    share () {
      this.showShare = true
    },
    directorySort () {
      this.directory.reverse()
    }
  },
  watch: {
    page () {
      setCUserLog({
        resources_guid: this.$route.params.resources_guid,
        c_user_guid: this.userGuid,
        agency_guid: this.agencyGuid,
        c_user_location: this.userCityName,
        resources_ext: this.page,
        resources_type: 1
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.slide-enter-active, .slide-leave-active
  transition: all 0.3s

.slide-enter, .slide-leave-to
  transform: translate3d(100%, 0, 0)

.ebook-detail
  position absolute
  z-index 1000
  top 0
  left 0
  right 0
  bottom 0
  background rgba(245, 245, 244, 1)

  .scroll
    position absolute
    top 84px
    left 0
    right 0
    bottom 97px
    bottom calc(constant(safe-area-inset-bottom) + 97px)
    bottom calc(env(safe-area-inset-bottom) + 97px)
    overflow hidden
</style>
